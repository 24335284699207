#agendaSearch {
  background: #ebf1f5 0% 0% no-repeat padding-box;
  border: 1px solid #c2c7cb;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  font-family: proxima-nova;
  font-size: 20px;
  padding: 0.5rem;
}

#agendaDropdown {
  height: 100%;
  width: 100%;
}

#agendaDropdown .dropdown-toggle, 
#registrationBox .dropdown-toggle {
  width: 100% !important;
  background: #ebf1f5 0% 0% no-repeat padding-box;
  border: 1px solid #c2c7cb;
  border-radius: 5px;
  opacity: 1;
  color: #757575;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: proxima-nova;
  font-size: 20px;
}

#agendaDropdown .dropdown-toggle::after, 
#registrationBox .dropdown-toggle::after {
  color: #000000;
  border-width: 10px;
  border-radius: 10px;
}

#agendaDropdown .dropdown-menu,
#registrationBox .dropdown-menu {
  font-family: proxima-nova;
  width: 100%;
}

div.dropdown {
  width: 100%;
  height: 100%;
}

.agendaSchedule {
  height: 100%;
}

.sessionCard {
  background-color: #EBF1F5;
  border-radius: 10px;
  padding: 2rem;
  min-height: 12rem;
}

.sessionCard > .registrationTitle {
  text-align: center;
}

.sessionImage {
  height: 100px;
  width: 100px;
  border-radius: 100%;
  margin-right: 5px;
}

.sessionTitle {
  text-align:left;
  font-weight: bold;
  font-family: proxima-nova, sans-serif;
  font-size: clamp(24px, 3vw, 30px);
  line-height: 1.5rem;
}

.sessionTime {
  font-family: proxima-nova, sans-serif;
  color: #414c59;
  margin: 0;
  margin-bottom: .2rem;
  text-align: left;
  font-size: 18px;
}

.speakerGroup {
  display: flex;
  flex-direction: row;
  width: fit-content;
  min-width: 250px;
}

.sessionRow {
  width: 100%;
  margin: auto;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: auto;
  cursor: grab;
  overflow-x: auto;
  scrollbar-width: none;

}

.selectDisable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.sessionRow::-webkit-scrollbar {
  display:none;
}

a.agendaGlanceBtn {
  font-weight: 500;
  font-size: 1.5rem;
  margin-left: 1rem;
  text-decoration: underline;
  max-width: max-content;
}

#agendaAtGlance {
  font-weight: 500;
  font-size: 1.5rem;
  margin-left: 1rem;
  text-decoration: underline;
  max-width: max-content;
  color: rgb(30, 102, 235);
  background-color: transparent;
  border-style: none;
}

.agendaColumn {
  display: flex;
  align-items: start;
  flex-direction: column;
}

#agendaContainer {
  height: fit-content;
}