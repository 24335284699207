.reservationImage {
    width: clamp(15rem, 100%, 30rem);
    border-radius: 30px;
    height: 360px;
}

@media (max-width: 992px) {
    .reservationImage {
        height: 240px;
    }
}

.hotelBox {
    display: flex;
    align-items: center;
    background-color: #ebf1f5;
    border-radius: 30px;
    padding: 1.5rem;
    min-height: 25rem;
}

.hotelBox div,
.reservationImage {
    min-height: 100%;
}

.hotelBox .gradientBtn {
    min-width: max-content;
}

.bulletPoint {
    display: list-item;
    list-style-position: inside;
}

.smallText {
    font-size: .9rem;
}

.hotelPrice,
.hotelInfo,
.hotelBookingInfo,
.hotelBox h3.surveyTitle {
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content:center;
    text-align: center;
}

.hotelPrice strong {
    font-size: 1rem;
}

.hotelCutoff {
    display: inline-block;
    font-size: .95rem;
}

.hotelBox .surveyTitle {
    font-size: clamp(25px, 2.2vw, 30px);
}

.hotelAddress {
    display: flex;
    justify-content: center;
}

@media (max-width: 1000px) {   
    .hotelBox h3.surveyTitle,
    .hotelBox p.hotelAddress,
    .hotelBox strong.hotelDistanceText{
        text-align: center;
        display: flex;
        justify-content: center;
    }
}