@media (min-width:576px) {
    .speakerModal .modalImgCol {
        display: flex;
        justify-content: flex-end;
        align-items:end;
    }
}

@media (max-width: 992px) {
    .speakerModal .modalImgCol {
        display: flex;
        justify-content: center;
        align-items:end;
    }
    .speakerModal .speakerName,
    .speakerModal .speakerTitle {
        text-align: center;
    }
}

.modalName{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.modal-body {
    padding:2rem;
}

.speakerModal {
    min-width: 50vw;
}

.speakerImg {
    border-radius: 100%;
    width: 225px;
    height: 225px;
}

.speakerTitle {
    font-family: proxima-nova;
    color: #414C59;
    line-height: 1.2rem;
}

.speakerName {
    color: #03081C;
    font-family: proxima-nova;
}

.boldSpeakerTitleBox {
    padding-left:1rem;
    padding-right:1rem;
}