.App {
  text-align: center;
  min-height: calc(100vh - 194px);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#landingScreen {
  min-height: calc(100vh - 100px);
  display: inline-block;
  justify-content: center;
  background-color: transparent;
}

.confBg {
  background-image: url("./assets/Hero.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

a.nav-link {
  text-align: center;
  font-weight: medium;
  font-family: proxima-nova, sans-serif;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 1;
}

a.nav-link:hover {
  text-decoration: underline 3px #5daf5f;
}

div > .navbar-collapse {
  display: flex;
  justify-content: flex-end;
}

button.navbar-toggler {
  border-style: none;
}

.landingSubtitle {
  color: #5daf5f;
  text-align: center;
  font: normal normal bold 35px/80px proxima-nova;
  letter-spacing: 0px;
  color: #5daf5f;
  opacity: 1;
  line-height: 2rem;
  font-size: clamp(15px, 7vw, 35px);
}

.landingTitle {
  text-align: center;
  font: normal normal 800 80px/85px proxima-nova;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: clamp(50px, 10vw, 80px);
  line-height: clamp(50px, 10vw, 80px);
}

.confLogo,
.logoBox {
  max-width: 60vw;
}

.screen {
  min-height: 100vh;
}

#speakerScreen {
  background-color: #ebf1f5;
}

#agendaScreen {
  background-color: #03081c;
}

#agendaScreen .title {
  color: #fff;
}

.heroBg {
  background-image: url("./assets/Hero.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#welcomeScreen {
  background-color: white;
}

button.gradientBtn,
a.gradientBtn {
  color: #fff;
  background: transparent linear-gradient(108deg, #2b85bb 0%, #4ca181 40%, #4ca181 60%, #2b85bb 100%);
  background-size: 200%;
  border: 0px;
  border-radius: 40px;
  padding: 0.75rem;
  padding-left: 2.9rem;
  padding-right: 2.9rem;
  min-width: 17rem;
  max-width: max-content;
  font-family: open-sans;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 38px;
  font-size: 20px;
  margin: 1rem;
  text-decoration: none;
  line-height:1rem;
  transition: all .2s ease-out;
}

button.gradientBtn:hover,
a.gradientBtn:hover {
  color: #fff;
  background-position: 100%;
}

.outlineBtn {
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  border-width: 2px;
  border-radius: 40px;
  border-color: #5daf5f;
  border-style: solid;
  text-decoration: none;
  min-width: 17rem;
  max-width: max-content;
  font-family: open-sans;
  font-size: 20px;
  padding: 0.75rem;
  padding-left: 2.9rem;
  padding-right: 2.9rem;
  margin: 1rem;
  line-height: 1rem;
}

.splashBtns {
  display: flex;
}

@media (min-width: 991px) {
  .splashBtns:first-child {
    justify-content: flex-end;
  }
  #aboutImgBox {
    justify-content: center;
  }
}

@media (max-width: 990px) {
  .splashBtns {
    justify-content: center;
  }
  button.gradientBtn,
  a.outlineBtn {
    min-width: 300px;
  }
}

.outlineBtn:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

#aboutImg {
  max-width: 100%;
  width: 100%;
  max-height: 65vh;
}

#venueImgBox {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.subtitle {
  color: #2b85bb;
  font-weight: bold;
  font-family: proxima-nova;
  font-size: 1.25em;
  text-align: left;
}

.subtext {
  text-align: left;
  font: normal normal medium proxima-nova;
  font-size: 24px;
  letter-spacing: 0px;
  color: #414c59;
  opacity: 1;
}

.title {
  font: normal normal 800 60px/60px proxima-nova;
  font-size: clamp(30px, 10vw, 60px);
  line-height: clamp(30px, 10vw, 60px);
  letter-spacing: 0px;
  color: #03081c;
  opacity: 1;
  text-align: center;
}

.aboutTextBox .title {
  text-align: left;
}

#aboutDesc {
  font-size: 1.25em;
  text-align: left;
  letter-spacing: 0px;
  font: proxima-nova;
  color: #414c59;
  opacity: 1;
  margin-top: 16px;
  margin-bottom: 2rem;
}

.footer {
  padding-top: 60px;
  padding-bottom: 60px;
}

.footerBg {
  background-color: #03081c;
}

.darkText {
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
}