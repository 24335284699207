#venueTextBox {
    display: flex;
    flex-direction: column;
    justify-content:center;

}

#venueTextBox .title {
    font-size: clamp(40px, 5vw, 60px);
    line-height: 2.5rem;
}

#venueTextBox .subtitle {
    line-height:1rem;
    margin-top: 1rem;
}

a.outlineBtn {
    color: #2B85BB;
    line-height: 1rem;
}

.venueBtnBox {
    display: flex;
    max-width: 325px;
}

@media (max-width: 810px) {
    #venueImg {
        height: 475px;
    }
    .venueBtnBox {
        margin: auto;
    }
}

#venueScreen {
    display: flex;
    align-items: center;
    min-height:74vh;
}

.venueBtnBox > button,
.venueBtnBox > a {
    font-size: 1.2rem;
}

#venueImg {
    height: 100%;
    max-height: 550px;
    width: 100%;
    max-width: 350px;
}