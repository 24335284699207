.captainLogo {
    max-width: 60%;
    min-width: 20rem;
}

.powerPointLogo {
    max-width: 20%;
    min-width: 8rem;
}

.resourceText {
    font-family: proxima-nova;
    font-size: 1.1rem;
    width: 70%;
    min-width: 20rem;
    color: #414c59;
}

#resourceTitle{
    text-align: center;
}