#registrationBtn .gradientBtn {
  min-width: 100%;
  text-align: center;
}

.formTitle {
  text-align: left;
  color: #414c59;
  width: 100%;
  font: normal normal bold 20px/35px proxima-nova;
  letter-spacing: 0px;
  line-height: 1rem;
}

input.formInput {
  background: #ebf1f5 0% 0% no-repeat padding-box;
  border: 1px solid #c2c7cb;
  border-radius: 5px;
  opacity: 1;
  padding: 0.5rem;
  padding-left: 1rem;
  font-size: 20px;
}
#registrationBox .gradientBtn {
  line-height: 1.5rem;
  margin-left: 0;
}

.registrationTitle {
  text-align: left;
  font: normal normal bold 30px/27px proxima-nova;
  letter-spacing: 0px;
  color: #2b85bb;
  opacity: 1;
}

#registrationBox .registerDropdown {
  height: max-content;
}

#registrationBox .dropdown-toggle,
#registrationBox .dropdown-menu {
  width: 100%;
}

#registrationBox .dropdown-menu {
  overflow-y: scroll;
  scrollbar-width: none;
}

.formTitle span {
  font-weight: normal;
  font-size: clamp(1.1rem, 1vw, 1.3rem);
}

.registerDropdown .form-check {
  padding-top: 3px;
  padding-bottom: 3px;
}

#contactEmail {
  color: #0d6efd;
  text-decoration: underline;
}

#contactEmail:hover {
  cursor: pointer;
  color: #0a58ca;
}

.dropdownCheck:hover {
  background-color: rgb(99, 96, 96);
}

span.registrationTotal {
  display: inline-block;
  justify-self: flex-end;
  align-items: flex-end;
}

div.agendaModal h3.title {
  font-size: 2.5rem;
  margin-bottom: 0;
}

.agendaSpan {
  text-decoration: none;
  color: black;
}

ul {
  margin-left: 1rem;
  list-style-position: inside;
}

.formTitle > .agendaGlanceBtn {
  font-size: 1.2rem;
}

.redText {
  color: red;
}

h5.warningText {
  text-align: left;
}

p.warningText {
  text-align: left;
  margin-left: 2rem;
}
