.survey {
  background-color: #ebf1f5;
  border-radius: 30px;
  padding: 2rem;
}

.surveyTitle {
  color: #03081c;
  text-decoration: none;
  margin-bottom: 8px;
  font-family: proxima-nova, sans-serif;
  font-size: 30px;
  font-weight: bold;
  text-align: left;
}

.surveyDescription {
  color: #414c59;
  font-family: proxima-nova, sans-serif;
  letter-spacing: 0px;
  text-align: left;
  line-height: 1.2rem;
}

.surveyBtn {
  display: flex;
  align-items: center;
  margin: auto;
  max-height: fit-content;
}

@media (min-width: 811px) {
    .surveyBtn :first-child {
        display: flex;
        justify-content:flex-start;
    }
    .surveyBtn :last-child {
        display: flex;
        justify-content: flex-end;
    }
}

/* @media (mid-width: 1200px) {
    .survey {
        
    }
} */

.surveyBtn .gradientBtn {
  line-height: 1.25rem;
  min-width: fit-content;
  padding-left: 2rem;
  padding-right: 2rem;
}

.surveyTime {
  display: flex;
  align-items: flex-end;
  font-weight: 600;
  font-family: open-sans;
  font-size: 16px;
  text-align: right;
  max-width: fit-content;
  max-height: fit-content;
  line-height: 1rem;
  letter-spacing: 0px;
  margin: 0;
  opacity: 1;
  color: #757575;
}

.surveyRow {
  display: flex;
  justify-content: space-around;
}

.surveyRow > .registrationTitle {
  font-size: 2.25rem;
}